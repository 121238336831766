import React from 'react'
import { Modal } from 'antd'
import { Link } from 'gatsby'

const CompleteProfileModal = ({ modalOpen, close }) => {
  return (
    <Modal
      width={640}
      footer={null}
      visible={modalOpen}
      onCancel={close}
      okText="Save"
      className="p-0 candidateAlertModal shadow-lg"
    >
      <div className="modal-head bg-voyage py-5 px-6 lg:px-9 rounded-tl rounded-tr flex items-center justify-between">
        <p className="font-sans font-bold text-[20px] leading-tight text-white mb-0">
          Complete Your Profile
        </p>
        <img
          onClick={close}
          className="w-4 cursor-pointer mb-0"
          src="/icons/icon-modal-close.svg"
          alt="Close modal icon"
        />
      </div>
      <div className="flex flex-col items-center bg-white px-6 lg:px-9 py-8 lg:py-10 rounded-bl rounded-br">
        <div className="w-14 h-14 flex justify-center items-center rounded-full bg-[#059913]">
          <img src="/icons/icon-check.svg" className="w-8 h-8" />
        </div>
        <div className="text-base leading-snug text-black/[.95] text-center mt-9">
          Get the most out of MedReps. Employers hiring on MedReps run searches
          based on your profile information. The more we know about what you're
          looking for, the smarter your recommendations will be!
        </div>
        <div className="w-[60%] lg:w-[20%] mx-auto mt-8">
          <Link to="/profile">
            <button className="bg-voyage w-full py-3 text-white font-sans font-bold mb-0 w-full">
              Edit Profile
            </button>
          </Link>
        </div>
      </div>
    </Modal>
  )
}
export default CompleteProfileModal
