import React, { useState } from 'react'
import Dashboard from '../../components/pageComponents/memberDashboard'
import PageNav from '../../components/pageComponents/common/pageNav'
import Wrapper from '../../components/wrapper'
import CompleteProfileModal from '../../components/Modals/completeProfile'

const Children = props => {
  const { location } = props

  const [completeProfileModalVisible, setCompleteProfileModalVisible] =
    useState(location.state?.firstRegistration)

  const closeCompleteProfileModal = () => {
    setCompleteProfileModalVisible(false)
  }

  return (
    <>
      <PageNav navItem="Dashboard" {...props} />
      <Dashboard {...props} />
      <CompleteProfileModal
        modalOpen={completeProfileModalVisible}
        close={closeCompleteProfileModal}
      />
    </>
  )
}

const MemberDashboardPage = ({ location }) => {
  return (
    <Wrapper privateRoute location={location} title="Dashboard | MedReps.com">
      <Children location={location} />
    </Wrapper>
  )
}
export default MemberDashboardPage
