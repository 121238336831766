import React, { useState, useEffect, useMemo } from 'react'
import { Dropdown, Select, Tag, Table, message, Menu } from 'antd'
import {
  dateFormatHandler,
  displayValueHandler,
  permissionsHandler,
  restructureJob,
  serialize,
  usDateFormat,
} from '../../../functions'
import { useWindowSize } from '../../../../windowResize'
import { navigate, Link } from 'gatsby'
import cookies from 'react-cookies'
import axios from 'axios'
import moment from 'moment'
import { strapiURL } from '../../../config'
import { emailFrequencyCandidate } from '../../../data'
import ApplyJobModal from '../../Modals/jobApply'
import ApplySuccessModal from '../../Modals/applySuccessModal'
import EditSearchModal from '../../Modals/candidateSearchEditModal'
import { useSelector, useDispatch } from 'react-redux'
import { setProducts } from '../../../redux/actions/user'

const MemberDashboard = ({ userProfile }) => {
  let _windowSize = useWindowSize()
  const [dashboardData, setDashboardData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [applyJob, setApplyJob] = useState(null)
  const [sucessModal, setSuccessModal] = useState(false)
  const [featuredArticles, setFeaturedArticles] = useState([])
  const [editSearchModal, setEditSearchModal] = useState(false)
  const [editSearchItem, setEditSearchItem] = useState(null)
  const [savedJobList, setSavedJobList] = useState([])
  const [alerts, setAlerts] = useState(null)
  const dispatch = useDispatch()
  const { Option } = Select
  let user = cookies.load('user')
  const storeProducts = useSelector(state => state.user).products
  let products = permissionsHandler(storeProducts, user?.role?.id)
  let isAllowed = products?.subscriptionPurchased

  let largeScreen = _windowSize?.width && _windowSize?.width > 1023

  useEffect(() => {
    axios
      .post(strapiURL + '/candidate-profiles/member/details', {
        username: user?.username,
      })
      .then(res => {
        setDashboardData(res?.data)
        setAlerts(res?.data?.candidate_searches || [])
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })

    axios.post(strapiURL + '/articles/searchIndex', {}).then(res => {
      setFeaturedArticles(res.data?.hits?.slice(0, 5))
    })
    handleLoadProducts()
  }, [])

  const handleLoadProducts = async () => {
    if (user) {
      const { data: products } = await axios.get(
        strapiURL + `/employers/${user?.user?.id}/subscriptionsAndCredits`
      )

      dispatch(setProducts(products))
    }
  }

  useEffect(() => {
    if (userProfile?.id) {
      handleLoadSavedJobs()
    }
  }, [userProfile?.id])

  const handleLoadSavedJobs = async () => {
    try {
      const result = await axios.get(
        strapiURL +
          `/candidate-profiles/${userProfile?.id}/getSavedJobs?page=1`,
        {}
      )
      let _savedJobs = result?.data?.savedJobs
      setSavedJobList(_savedJobs || [])
    } catch (error) {
      console.log('saved job --- error', error)
    }
  }

  const onRowRecentSearch = row => ({
    onClick: () => {
      if (row.detail) {
        let filters = JSON.parse(row.detail)
        if (filters.location) {
          filters.place_id = filters.location.placePredict?.value?.place_id
          filters.address = filters.location.placePredict?.label
        }
        delete filters.location
        delete filters.title
        if (row.keyword) {
          filters.keyword = row.keyword
        }
        const searchParams = new URLSearchParams(filters)
        navigate(`/job-search/?${searchParams.toString()}`)
      }
    },
  })

  const runSearch = val => {
    let filters = {}
    if (val.keyword) {
      filters.keyword = val.keyword
    }
    if (val.location?.placePredict) {
      filters.place_id = val.location.placePredict?.value?.place_id
      filters.address = val.location.placePredict?.label
    }
    if (val.distance) {
      filters.distance = val.distance
    }
    if (val.compensation_type) {
      filters.compensation_type = val.compensation_type
    }
    if (val.primary_function) {
      filters.primary_function = val.primary_function
    }
    if (val.min_compensation) {
      filters.min_compensation = parseInt(val.min_compensation)
    }
    if (val.max_compensation) {
      filters.max_compensation = parseInt(val.max_compensation)
    }
    if (val.travel_percentage) {
      filters.travel_percentage = val.travel_percentage
    }
    if (val.product_category) {
      filters.product_category = val.product_category
    }
    if (val.employer_type) {
      filters.employer_type = val.employer_type
    }
    if (val.remote_enabled) {
      filters.remote_enabled = val.remote_enabled
    }
    if (val.field_based) {
      filters.field_based = val.field_based
    }
    if (val.only_comp) {
      filters.only_comp = true
    }
    const searchParams = new URLSearchParams(filters)
    navigate(`/job-search/?` + searchParams)
  }

  const deleteSearchHandler = deleteId => {
    axios
      .delete(strapiURL + `/candidate-searches/${deleteId}`)
      .then(res => {
        message.success('Deleted')
        let _copy = [...alerts]
        _copy = _copy.filter(item => item?.id !== deleteId)
        setAlerts(_copy)
      })
      .catch(err => {
        message.error('Something went wrong.')
      })
  }

  const changeFrequencyHandler = (val, editItem) => {
    axios
      .put(strapiURL + `/candidate-searches/${editItem?.id}`, {
        frequency: val,
      })
      .then(res => {
        if (alerts?.length) {
          let _copyAll = [...alerts]
          _copyAll[_copyAll.findIndex(item => item?.id === editItem?.id)] =
            res.data
          setAlerts(_copyAll)
        }

        message.success('Search updated')
      })
      .catch(err => {
        message.error('Something went wrong')
      })
  }

  const handleUnSave = async (evt, id) => {
    evt.preventDefault()
    evt.stopPropagation()
    if (userProfile?.id) {
      try {
        const result = await axios.post(
          strapiURL + `/candidate-profiles/${userProfile?.id}/unSaveJob`,
          {
            jobId: id,
          }
        )
        if (result?.data?.result?.success) {
          message.success('Job unsaved')
          const updatedSavedJobList = savedJobList.filter(
            item =>
              item.id.toString() !== result?.data?.result?.data?.job.toString()
          )
          setSavedJobList([...updatedSavedJobList])
        }
      } catch (error) {
        message.error('Unsave Job Fail')
      }
    }
  }
  const searchCols = [
    {
      title: 'keyword',
      dataIndex: 'keyword',
      key: 'keyword',
      render: (text, record, index) => {
        return (
          <Tag className="rounded" color="#00567C">
            {text?.length > 32 ? text?.substring(0, 32).concat('...') : text}
          </Tag>
        )
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record, index) => {
        return (
          <div>
            <p className="text-last font-sans mb-0">
              {dateFormatHandler(text)}
            </p>
          </div>
        )
      },
    },
    {
      title: 'Number Of Search Results',
      dataIndex: 'results',
      key: 'results',
      width: 150,
      render: (text, record, index) => {
        return (
          <div>
            <p className="text-last font-sans mb-0">{text?.toLocaleString()}</p>
          </div>
        )
      },
    },
  ]
  const yourJobCols = [
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (text, record, index) => {
        let _job = restructureJob(record)
        return (
          <div className="flex">
            {user && isAllowed && (
              <div className="h-10 w-10 rounded shadow flex items-center justify-center">
                <div
                  className="h-8 w-8 bg-cover bg-center bg-no-repeat"
                  style={{
                    backgroundImage: `url(${
                      _job?.employerDetails?.image?.url ||
                      '/icons/icon-medical.png'
                    } )`,
                  }}
                />
              </div>
            )}
            <div className="flex-1 ml-2">
              <p className="text-xs font-bold font-sans mb-0.5">
                {_job?.title || ''}
              </p>
              <div className="flex items-center ">
                <img src="/icons/icon-location-pin.svg" className="mb-0" />
                <span className="text-[13px] text-location leading-0 ml-1">
                  {_job.location}
                </span>
              </div>
            </div>
          </div>
        )
      },
    },
    ...(user && isAllowed
      ? [
          {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
            render: (text, record, index) => {
              let _job = restructureJob(record)
              return (
                <div>
                  <p className="text-location text-last font-sans mb-0">
                    Company Name
                  </p>
                  <p className="text-last font-sans mb-0">{_job.employer}</p>
                </div>
              )
            },
          },
        ]
      : []),
    ,
    {
      title: 'Exp',
      dataIndex: 'exp',
      key: 'exp',
      render: (text, record, index) => {
        const expiry = moment(record.published_at).add(30, 'days').toDate()
        return (
          <div>
            <p className="text-location text-last font-sans mb-0">
              Expiry Date
            </p>
            <p className="text-last font-sans mb-0">
              {record.archived_date
                ? dateFormatHandler(record.archived_date)
                : dateFormatHandler(expiry)}
            </p>
          </div>
        )
      },
    },
    {
      title: 'Saved',
      dataIndex: 'saved',
      key: 'saved',
      render: (text, record) => {
        return (
          <Tag className="rounded text-[#01B3E3]" color="#EEF7FA">
            Saved
          </Tag>
        )
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '220px',
      render: (text, record, index) => {
        let _job = restructureJob(record)
        return (
          <div className="flex justify-center items-center">
            {record?.applied ? (
              <Link to={`/job-detail/${_job.slug}`}>
                <button className="bg-voyage py-1 px-3 font-sans text-white text-last font-bold rounded mr-2">
                  Applied
                </button>
              </Link>
            ) : (
              <>
                {products?.subscriptionPurchased ? (
                  <a className="bg-voyage py-1 px-3 font-sans text-white text-last font-bold rounded mr-2 ant-table-cell-btn">
                    Apply Now
                  </a>
                ) : (
                  <Link to={'/member-subscription/subscription'}>
                    <span className="bg-voyage py-1 px-3 font-sans text-white text-last font-bold rounded mr-2 ant-table-cell-btn">
                      Apply Now
                    </span>
                  </Link>
                )}
              </>
            )}
            <a
              className="bg-voyage py-1 px-3 font-sans text-white text-last font-bold rounded mr-2 ant-table-cell-btn"
              onClick={evt => handleUnSave(evt, _job.id)}
            >
              Unsave
            </a>
          </div>
        )
      },
    },
  ]

  const yourJobColsMobile = [
    {
      title: '',
      dataIndex: 'mobile',
      key: 'mobile',
      render: (text, record, index) => {
        let _job = restructureJob(record)
        const expiry = moment(record.published_at).add(30, 'days').toDate()

        return (
          <div className="flex">
            {user && isAllowed && (
              <div className="h-10 w-10 rounded shadow flex items-center justify-center">
                <div
                  className="h-8 w-8 bg-cover bg-center bg-no-repeat"
                  style={{
                    backgroundImage: `url(${
                      _job?.employerDetails?.image?.url ||
                      '/icons/icon-medical.png'
                    } )`,
                  }}
                />
              </div>
            )}
            <div className="flex-1 ml-2">
              <p className="text-sm font-bold font-sans mb-0.5">
                {_job?.title || ''}
              </p>
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-xs font-sans mb-0">
                    {record.archived_date
                      ? dateFormatHandler(record.archived_date)
                      : dateFormatHandler(expiry)}
                  </p>
                </div>
                <div>
                  <a
                    className="bg-voyage py-1 ml-3 px-3 font-sans text-white text-xs font-bold rounded mr-2 ant-table-cell-btn"
                    onClick={evt => handleUnSave(evt, _job.id)}
                  >
                    Unsave
                  </a>
                </div>
              </div>
            </div>
          </div>
        )
      },
    },
  ]

  const applicationCols = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record, index) => {
        if (!record.job) {
          return null
        }
        let _job = restructureJob(record?.job)
        return (
          <div className="flex">
            {user && isAllowed && (
              <div className="h-10 w-10 rounded shadow flex items-center justify-center">
                <div
                  className="h-8 w-8 bg-cover bg-center bg-no-repeat"
                  style={{
                    backgroundImage: `url(${
                      record?.employer_image?.url || '/icons/icon-medical.png'
                    } )`,
                  }}
                />
              </div>
            )}
            <div className="ml-2">
              <p className="text-xs font-bold font-sans mb-0.5">
                {_job?.title}
              </p>
              <div className="flex items-center ">
                <img src="/icons/icon-location-pin.svg" className="mb-0" />
                <span className="text-[13px] text-location leading-0 ml-1">
                  {_job?.location}
                </span>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      render: (text, record, index) => {
        if (!record.job) {
          return null
        }
        return (
          <div>
            <p className="text-location text-last font-sans mb-0">
              {record?.employer || 'Not Specified'}
            </p>
          </div>
        )
      },
    },
    {
      title: 'Expiry date',
      dataIndex: 'expiry',
      key: 'expiry',
      render: (text, record, index) => {
        if (!record.job) {
          return null
        }
        const expiry = moment(record.job?.published_at).add(30, 'days').toDate()
        return (
          <div>
            <p className="text-location text-last font-sans mb-0">
              {record.job?.archived_date
                ? dateFormatHandler(record.job?.archived_date)
                : dateFormatHandler(expiry)}
            </p>
          </div>
        )
      },
    },
    {
      title: 'Date Applied',
      dataIndex: 'date',
      key: 'date',
      render: (text, record, index) => {
        if (!record.job) {
          return null
        }
        return (
          <Tag className="rounded text-[#01B3E3]" color="#EEF7FA">
            {usDateFormat(record.appliedDate?.split('T')[0])}
          </Tag>
        )
      },
    },
  ]
  const applicationColsMobile = [
    {
      title: '',
      dataIndex: 'mobile',
      key: 'mobile',
      width: '100%',
      render: (text, record, index) => {
        if (!record.job) {
          return null
        }
        let _job = restructureJob(record?.job)

        return (
          <div className="flex flex-col">
            <div className="flex w-full">
              {user && isAllowed && (
                <div
                  className="h-12 w-12 bg-cover bg-center bg-no-repeat rounded shadow-lg"
                  style={{
                    backgroundImage: `url(${
                      record?.employer_image?.url || '/icons/icon-medical.png'
                    } )`,
                  }}
                />
              )}
              <div className="ml-2">
                <div className="flex flex-col">
                  <p className="text-[12px] text-location font-semibold leading-0 m-0">
                    Title
                  </p>
                  <p className="text-[13px] font-bold font-sans mb-0.5 leading-0">
                    {_job?.title}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-5">
              <div className="flex flex-col mr-3">
                <p className="text-[12px] text-location leading-0 m-0 font-semibold pb-1">
                  Location
                </p>
                <div className="flex items-center ">
                  <img src="/icons/icon-location-pin.svg" className="mb-0" />
                  <span className="text-[11px] text-location leading-0 ml-1">
                    {_job?.location}
                  </span>
                </div>
              </div>
              <div className="flex flex-col mr-3">
                <p className="text-[12px] text-location leading-0 m-0 font-semibold pb-1">
                  Company
                </p>
                <div className="flex items-center">
                  <span className="text-[11px] text-location leading-0 ml-1">
                    {record?.employer || 'Not Specified'}
                  </span>
                </div>
              </div>
              <div className="flex flex-col mr-3">
                <p className="text-[12px] text-location leading-0 m-0 font-semibold pb-1">
                  Date Applied
                </p>
                <div className="flex items-center">
                  <Tag className="rounded text-[#01B3E3]" color="#EEF7FA">
                    {usDateFormat(record.appliedDate?.split('T')[0])}
                  </Tag>
                </div>
              </div>
            </div>
          </div>
        )
      },
    },
  ]
  const alertCols = [
    {
      title: 'Search Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => {
        return (
          <p className="mb-0 font-sans font-bold">
            {record?.name || 'Not Specified'}
          </p>
        )
      },
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (text, record, index) => {
        return (
          <div className="flex items-center ">
            <img src="/icons/icon-location-pin.svg" className="mb-0" />

            <span className="text-last text-location leading-0 ml-1">
              {record?.remote_enabled && record?.location?.location
                ? record?.location?.location?.formattedAddress + '-Remote'
                : record?.location?.location
                ? record?.location?.location?.formattedAddress
                : !record?.location?.location && record?.remote_enabled
                ? 'Remote'
                : 'Not Specified'}
            </span>
          </div>
        )
      },
    },
    {
      title: 'Function',
      dataIndex: 'primary_function',
      key: 'primary_function',
      width: '20%',
      render: (text, record, index) => {
        return (
          <p className="text-location text-last font-sans mb-0">
            {record.primary_function
              ? displayValueHandler(record?.primary_function)
              : 'Not Specified'}
          </p>
        )
      },
    },
    {
      title: 'Compensation Range',
      dataIndex: 'compensation',
      key: 'compensation',
      render: (text, record, index) => {
        return (
          <>
            {!record.max_compensation || !record.min_compensation ? (
              <p className="text-location text-last font-sans mb-0">
                Not Specified
              </p>
            ) : (
              <p className="text-location text-last font-sans mb-0">
                ${record.min_compensation}K - ${record.max_compensation}K
              </p>
            )}
          </>
        )
      },
    },

    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
      render: (text, record, index) => {
        return (
          <Select
            onChange={val => {
              changeFrequencyHandler(val, record)
            }}
            className="font-sans text-last text-jobTag w-[100px]"
            value={record.frequency}
          >
            {emailFrequencyCandidate.map((freq, index) => {
              return (
                <Option
                  className="font-sans text-last text-jobTag"
                  value={freq}
                  key={index}
                >
                  {freq}
                </Option>
              )
            })}
          </Select>
        )
      },
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (text, record, index) => {
        return (
          <div className="flex items-center">
            <span
              onClick={() => {
                setEditSearchItem(record)
                setEditSearchModal(true)
              }}
              className="cursor-pointer font-sans text-last text-merlot mb-0"
            >
              Edit
            </span>
            <div className="h-4 w-px bg-merlot mx-2" />
            <span
              onClick={() => runSearch(record)}
              className="font-sans text-last text-merlot mb-0 cursor-pointer"
            >
              Run
            </span>
            <div className="h-4 w-px bg-merlot mx-2" />
            <span
              onClick={() => deleteSearchHandler(record.id)}
              className="font-sans text-last text-merlot mb-0 cursor-pointer"
            >
              Delete
            </span>
          </div>
        )
      },
    },
  ]
  const CompleteSvg = () => {
    return (
      <div className="h-4 w-4 rounded-full bg-[#5CB85C] flex items-center justify-center">
        <img src="/icons/icon-check.svg" className="mb-0" />
      </div>
    )
  }
  const UncheckSvg = () => {
    return (
      <div className="h-4 w-4 rounded-full bg-[#EBE4E6] flex items-center justify-center">
        <img src="/icons/icon-times.svg" className="mb-0" />
      </div>
    )
  }

  const appHistory = useMemo(() => {
    if (dashboardData?.application_history?.applications) {
      return dashboardData?.application_history?.applications.sort(
        (itemA, itemB) =>
          new Date(itemB.appliedDate) - new Date(itemA.appliedDate)
      )
    } else {
      return []
    }
  }, [dashboardData?.application_history?.applications])

  return (
    <div className="container mx-auto px-6 sm:px-0">
      {loading ? (
        <div
          className="w-full h-screen bg-no-repeat bg-cover pb-32"
          style={{
            backgroundImage: `url(/loaders/member-dashboard-loader.png)`,
          }}
        />
      ) : (
        <>
          {!products?.subscriptionPurchased && (
            <div className="hidden sm:block bg-[#F4FDFF] w-full mt-16 py-20 flex flex-col items-center text-center justify-center">
              <h1 className="font-playfair text-center font-normal text-headingTwo">
                Get more from <span className="text-merlot">MedReps!</span>
              </h1>
              <h1 className="font-playfair text-center font-normal text-headingThree">
                Become a MedReps Member
              </h1>
              <Link to="/member-subscription/subscription">
                <button className="mx-auto text-white py-4 px-10 bg-button cursor-pointer font-sans font-bold rounded text-lg mt-8">
                  Get Membership
                </button>
              </Link>
            </div>
          )}
          <div className="flex flex-wrap mt-0 sm:mt-16">
            <div className="w-full sm:w-2/5 rounded-lg bg-white shadow-profileCard">
              <div className="flex px-4 py-4 border-b border-bgSecondaryButton items-center">
                <img src="/icons/icon-profile.svg" className="mb-0" />
                <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
                  Your Profile
                </h1>
                <Link to="/profile">
                  <div className="flex items-center ml-2">
                    <span className="font-sans text-sm font-bold text-merlot">
                      Edit
                    </span>
                    <img
                      src="/icons/akar-icons_edit.png"
                      className="mb-0 ml-1 w-4"
                    />
                  </div>
                </Link>
              </div>
              <div className="flex flex-wrap items-center px-4 py-6 border-b border-bgSecondaryButton">
                <div className="w-full sm:w-1/2 flex items-center border-b sm:border-0 pb-4 sm:pb-0 mb-4 sm:mb-0">
                  <div className="flex items-center">
                    {!userProfile?.image?.url ? (
                      <div className="bg-bgSecondaryButton h-14 w-14 flex items-center justify-center">
                        <span className="font-sans font-bold text-sm mb-0 text-voyage">
                          {dashboardData?.name?.charAt(0)}
                        </span>
                      </div>
                    ) : (
                      <img
                        className="h-14 w-14"
                        src={userProfile?.image?.url}
                      />
                    )}
                    <div className="ml-4">
                      <p className="font-sans text-[16px] font-semibold mb-0.5">
                        {dashboardData?.name}
                      </p>
                      <p className="mb-0 text-[13px] opacity-70">
                        {userProfile?.created_at &&
                          'Member since' +
                            ' ' +
                            dateFormatHandler(userProfile?.created_at)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full sm:w-1/2 pl-0 sm:pl-4 border-0 sm:border-l border-bgSecondaryButton">
                  <div className="flex items-center w-full justify-between mb-5">
                    <div>
                      <p className="font-sans text-[14px] font-normal mb-0">
                        Upload Resume
                      </p>
                    </div>
                    {dashboardData?.defaultResume ? (
                      <CompleteSvg />
                    ) : (
                      <UncheckSvg />
                    )}
                  </div>
                  <div className="flex items-center w-full justify-between mb-5">
                    <div className="block">
                      <p className="font-sans text-[14px] font-normal mb-0">
                        Complete Profile
                      </p>
                      <p
                        className={`mb-0 font-sans text-[12px] ${
                          dashboardData?.statIndicators?.progress === 100
                            ? 'text-[#5CB85C]'
                            : 'text-red-500'
                        } `}
                      >
                        {dashboardData?.statIndicators?.progress}%
                      </p>
                    </div>
                    {dashboardData?.statIndicators?.progress === 100 ? (
                      <CompleteSvg />
                    ) : (
                      <UncheckSvg />
                    )}
                  </div>
                  <div className="flex items-center w-full justify-between mb-5">
                    <div className="block">
                      <p className="font-sans text-[14px] font-normal mb-0">
                        Create Job Alerts
                      </p>
                    </div>
                    {dashboardData?.candidate_searches?.length ? (
                      <CompleteSvg />
                    ) : (
                      <UncheckSvg />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex px-4 py-4 border-b border-bgSecondaryButton">
                <img src="/icons/icon-recent.svg" className="mb-0" />
                <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
                  Recent searches
                </h1>
              </div>
              <div className="border-b border-bgSecondaryButton">
                <Table
                  bordered={false}
                  className="hide-table-header-background hide-table-body-rows-border hide-table-header-cols-border cursor-pointer"
                  columns={searchCols}
                  dataSource={(dashboardData?.performed_searches || []).sort(
                    (a, b) => new Date(b['date']) - new Date(a['date'])
                  )}
                  pagination={false}
                  onRow={onRowRecentSearch}
                />
              </div>
              <div className="hidden sm:flex flex px-4 py-4 border-b border-bgSecondaryButton">
                <img src="/icons/icon-advice.svg" className="mb-0" />
                <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
                  Career Advice
                </h1>
              </div>
              <div className="hidden sm:flex articles-area px-4 py-4 flex-wrap">
                {featuredArticles.length ? (
                  <div className="grid grid-cols-1 gap-4">
                    {featuredArticles.map((article, index) => {
                      return (
                        <div className="w-full flex gap-3" key={index}>
                          <div
                            className="h-28 w-36 rounded border bg-cover bg-no-repeat bg-center"
                            style={{
                              backgroundImage: `url(${article?.imageUrl})`,
                            }}
                          />
                          <div className="flex-1 py-0.5">
                            <h1 className="text-sm font-sans font-semibold font-sans mb-1">
                              {article.title}
                            </h1>
                            <p className="mb-1 font-sans text-xs line-clamp-3">
                              {article?.description || ''}
                            </p>
                            <Link
                              to={`/medical-sales-careers/${article.slug}`}
                              className="text-merlot text-xs"
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <p>No Articles Found.</p>
                )}
              </div>
            </div>
            {/* ---- */}
            <div className="w-full sm:w-3/5 pl-0 sm:pl-4 mt-4 sm:mt-0">
              <div className="rounded-lg bg-white shadow-profileCard">
                <div className="flex px-4 py-4 border-b border-bgSecondaryButton justify-between">
                  <div className="flex items-center">
                    <img src="/icons/icon-baksa.svg" className="mb-0" />
                    <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
                      Your Jobs
                    </h1>
                  </div>
                  {savedJobList?.length ? (
                    <Link
                      to="/member-dashboard/your-jobs"
                      className="bg-voyage py-1 px-3 font-sans text-white text-last font-bold rounded mr-2"
                    >
                      View All
                    </Link>
                  ) : (
                    <div />
                  )}
                </div>
                <Table
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {
                        window.open('/job-detail/' + record?.id, '_self')
                      },
                    }
                  }}
                  rowClassName={(record, index) => {
                    return record?.issuggested
                      ? 'bg-activeRow hover:bg-activeRow cursor-pointer'
                      : 'bg-white cursor-pointer'
                  }}
                  columns={largeScreen ? yourJobCols : yourJobColsMobile}
                  dataSource={savedJobList?.slice(0, 5)}
                  showHeader={false}
                  pagination={false}
                />
              </div>
              <div className="rounded-lg bg-white shadow-profileCard mt-4">
                <div className="flex items-center px-4 py-4 border-b border-bgSecondaryButton justify-between">
                  <div className="flex items-center">
                    <img src="/icons/icon-application.svg" className="mb-0" />
                    <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
                      Application History
                    </h1>
                  </div>
                  {appHistory.length ? (
                    <Link
                      to="/member-dashboard/application-history"
                      className="bg-voyage py-1 text-center font-sans text-white text-last font-bold rounded mr-2 w-[75px]"
                    >
                      View All
                    </Link>
                  ) : null}
                </div>

                <Table
                  className="hide-table-header-background hide-table-header-cols-border"
                  columns={
                    largeScreen ? applicationCols : applicationColsMobile
                  }
                  rowClassName="cursor-pointer"
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {
                        window.open('/job-detail/' + record?.job?.slug, '_self')
                      },
                    }
                  }}
                  dataSource={
                    appHistory.length > 10
                      ? appHistory.slice(0, 9)
                      : appHistory || []
                  }
                  pagination={false}
                  bordered={false}
                />
              </div>
            </div>
          </div>
          <div className="mt-20">
            <div className="flex px-4 py-4 border-b border-bgSecondaryButton ">
              <img src="/icons/icon-alert-1.svg" className="mb-0" />
              <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
                Your Alerts
              </h1>
            </div>
            <Table
              className="hide-table-header-background hide-table-header-cols-border hidden sm:block"
              columns={alertCols}
              dataSource={alerts || []}
              pagination={false}
              bordered={false}
            />
            <div className="alerts-dashboard mb-6 visible sm:hidden">
              {alerts &&
                alerts.map(alert => {
                  return (
                    <div
                      key={alert.id}
                      className="list flex justify-between items-center py-6"
                    >
                      <h3>{alert.name}</h3>
                      <Select
                        onChange={val => {
                          changeFrequencyHandler(val, alert)
                        }}
                        className="font-sans text-last text-jobTag w-[100px]"
                        value={alert.frequency}
                      >
                        {emailFrequencyCandidate.map((freq, index) => {
                          return (
                            <Option
                              className="font-sans text-last text-jobTag"
                              value={freq}
                              key={index}
                            >
                              {freq}
                            </Option>
                          )
                        })}
                      </Select>
                      <Dropdown
                        trigger={['click']}
                        overlay={
                          <Menu>
                            <Menu.Item>
                              <a
                                className="font-sans text-sm"
                                onClick={() => {
                                  setEditSearchItem(alert)
                                  setEditSearchModal(true)
                                }}
                              >
                                Edit
                              </a>
                            </Menu.Item>
                            <Menu.Item>
                              <a
                                className="font-sans text-sm"
                                onClick={() => runSearch(alert)}
                              >
                                Run
                              </a>
                            </Menu.Item>
                            <Menu.Item>
                              <a
                                className="font-sans text-sm"
                                onClick={() => deleteSearchHandler(alert.id)}
                              >
                                Delete
                              </a>
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <a onClick={e => e.preventDefault()}>
                          <img
                            src="/icons/options.png"
                            className="self-center cursor-pointer"
                          />
                        </a>
                      </Dropdown>
                    </div>
                  )
                })}
            </div>
          </div>
        </>
      )}
      <ApplyJobModal
        currentJob={applyJob}
        setApplyJob={setApplyJob}
        userProfile={dashboardData}
        setUserProfile={setDashboardData}
        setSuccessModal={setSuccessModal}
      />
      <ApplySuccessModal modalOpen={sucessModal} />
      <EditSearchModal
        modalOpen={editSearchModal}
        setModalOpen={setEditSearchModal}
        editItem={editSearchItem}
        setEditItem={setEditSearchItem}
        userProfile={userProfile}
        currentData={alerts}
        setCurrentData={setAlerts}
      />
    </div>
  )
}
export default MemberDashboard
